<template>
  <div class="container">
    <div class="list">
      <div v-for="(item, i) in arr" :key="i" class="item-wrap flex flex-pack-center">
        <div class="item" @click="goDesignZizhi(item)">
          <div v-if="item.logo.length > 1" class="prev" @click="prev(i)">
            <i class="el-icon-arrow-left"></i>
          </div>
          <div v-if="item.logo.length > 1" class="next" @click="next(i)">
            <i class="el-icon-arrow-right"></i>
          </div>
          <div class="img-wrap img-wrap-1">
            <div
              class="inner flex flex-align-center"
              :style="{transform: 'translateX(' + translateX[i] + 'px)'}"
            >
              <img v-for="(tag, j) in item.logo" :key="j" :src="IMG_URL_PRE+tag+'!width_250px'" />
              <!-- <img :src="IMG_URL_PRE+item.logo+'!width_250px'" /> -->
            </div>
          </div>
          <div class="name">{{item.name}}</div>
          <div v-if="item.aptitude && item.aptitude.ability_service && item.aptitude.ability_service.fwbq.length>0" class="label-wrap flex">
            <span v-for="(n,i) in item.aptitude.ability_service.fwbq" :key="i" :class="`color-${randomInt(4)}`">{{n.v}}</span>
          </div>
        </div>
        <div v-for="(n, i) in item.factoryItems" :key="i" class="item" @click="toDetail(n.id)">
          <div class="img-wrap">
            <div class="inner flex flex-align-center">
              <img :src="IMG_URL_PRE+n.photoRender[0]+'!width_250px'" />
            </div>
          </div>
          <div class="name">{{n.name}}</div>
          <div class="feature">
            <span v-if="n.style">{{n.style.name}}</span>
            <span v-if="n.cat">{{n.cat.name}}</span>
            <span v-if="n.series">{{n.series.name}}</span>
          </div>
          <!-- <div class="watch-count">已有人查看</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { STATIC_URL_PRE, IMG_URL_PRE } from '@/config';
export default {
  props: ['comp'],
  data() {
    return {
      IMG_URL_PRE,
      index: [],
      translateX: [],
      arr: [
        {
          name: "赣州市端瑞家具有限公司",
          logo: [
            STATIC_URL_PRE+"design/images/sale/mall/comp/duanrui_logo.jpg!width_250px",
            STATIC_URL_PRE+"design/images/sale/mall/comp/duanruimentou.jpg!width_250px"
          ],
          tags: [
            "外贸定制",
            "橡木",
            "成品",
            "交货准时",
            "年销量高",
            "新款多",
            "支持退款",
            "支持分期"
          ],
          arr: [
            {
              img: STATIC_URL_PRE+"design/images/sale/mall/comp/dr_pre_img1.jpg!width_250px",
              name: "中式茶桌南榆木茶台椅组合实…",
              a: "欧式风格",
              b: "金乌木",
              c: "",
              uv: 342
            },
            {
              img: STATIC_URL_PRE+"design/images/sale/mall/comp/dr_pre_img2.jpg!width_250px",
              name: "新中式茶桌椅组合 现代简约实…",
              a: "欧式风格",
              b: "金乌木",
              c: "",
              uv: 435
            },
            {
              img: STATIC_URL_PRE+"design/images/sale/mall/comp/dr_pre_img3.jpg!width_250px",
              name: "新中式功夫泡茶桌原木高档茶桌",
              a: "欧式风格",
              b: "金乌木",
              c: "",
              uv: 231
            }
          ]
        },
        {
          name: "赣州市恩多元家具有限公司",
          logo: [
            STATIC_URL_PRE+"design/images/sale/mall/comp/enduoyaun_logo.jpg!width_250px",
            STATIC_URL_PRE+"design/images/sale/mall/comp/enduoyuanmentou.jpg!width_250px"
          ],
          tags: [
            "外贸定制",
            "橡木",
            "成品",
            "交货准时",
            "年销量高",
            "新款多",
            "支持退款",
            "支持分期"
          ],
          arr: [
            {
              img: STATIC_URL_PRE+"design/images/sale/mall/comp/enduoyaun_img1.jpg!width_250px",
              name: "中式茶桌南榆木茶台椅组合实…",
              a: "欧式风格",
              b: "金乌木",
              c: "",
              uv: 789
            },
            {
              img: STATIC_URL_PRE+"design/images/sale/mall/comp/enduoyaun_img2.jpg!width_250px",
              name: "新中式茶桌椅组合 现代简约实…",
              a: "欧式风格",
              b: "金乌木",
              c: "",
              uv: 996
            },
            {
              img: STATIC_URL_PRE+"design/images/sale/mall/comp/enduoyaun_img3.jpg!width_250px",
              name: "新中式功夫泡茶桌原木高档茶桌",
              a: "欧式风格",
              b: "金乌木",
              c: "",
              uv: 987
            }
          ]
        },
        {
          name: "江西竹林新家家具有限公司",
          logo: [
            STATIC_URL_PRE+"design/images/sale/mall/comp/baijaitanyulogo.jpg!width_250px",
            STATIC_URL_PRE+"design/images/sale/mall/comp/baijaitanyu.jpg!width_250px"
          ],
          tags: [
            "外贸定制",
            "橡木",
            "成品",
            "交货准时",
            "年销量高",
            "新款多",
            "支持退款",
            "支持分期"
          ],
          arr: [
            {
              img: STATIC_URL_PRE+"design/images/sale/mall/comp/baijaitanyu1.jpg!width_250px",
              name: "中式茶桌南榆木茶台椅组合实…",
              a: "欧式风格",
              b: "金乌木",
              c: "",
              uv: 876
            },
            {
              img: STATIC_URL_PRE+"design/images/sale/mall/comp/baijaitanyu2.jpg!width_250px",
              name: "新中式茶桌椅组合 现代简约实…",
              a: "欧式风格",
              b: "金乌木",
              c: "",
              uv: 890
            },
            {
              img: STATIC_URL_PRE+"design/images/sale/mall/comp/baijaitanyu3.jpg!width_250px",
              name: "新中式功夫泡茶桌原木高档茶桌",
              a: "欧式风格",
              b: "金乌木",
              c: "",
              uv: 986
            }
          ]
        },
        {
          name: "江西名府宫家具有限公司",
          logo: [
            STATIC_URL_PRE+"design/images/sale/mall/comp/mingfu_logo.png!width_250px",
            STATIC_URL_PRE+"design/images/sale/mall/comp/mingfu_mingtou.jpg!width_250px"
          ],
          tags: [
            "外贸定制",
            "橡木",
            "成品",
            "交货准时",
            "年销量高",
            "新款多",
            "支持退款",
            "支持分期"
          ],
          arr: [
            {
              img: STATIC_URL_PRE+"design/images/sale/mall/comp/mf_pre_img1.jpg!width_250px",
              name: "中式茶桌南榆木茶台椅组合实…",
              a: "欧式风格",
              b: "金乌木",
              c: "",
              uv: 678
            },
            {
              img: STATIC_URL_PRE+"design/images/sale/mall/comp/mf_pre_img2.jpg!width_250px",
              name: "新中式茶桌椅组合 现代简约实…",
              a: "欧式风格",
              b: "金乌木",
              c: "",
              uv: 888
            },
            {
              img: STATIC_URL_PRE+"design/images/sale/mall/comp/mf_pre_img3.jpg!width_250px",
              name: "新中式功夫泡茶桌原木高档茶桌",
              a: "欧式风格",
              b: "金乌木",
              c: "",
              uv: 975
            }
          ]
        },
        {
          name: "赣州市文华家瑞家具实业有限公司",
          logo: [
            STATIC_URL_PRE+"design/images/sale/mall/comp/wenhauduanrui_logo.jpg!width_250px",
            STATIC_URL_PRE+"design/images/sale/mall/comp/wenhauduanrui_mentou.jpg!width_250px"
          ],
          tags: [
            "外贸定制",
            "橡木",
            "成品",
            "交货准时",
            "年销量高",
            "新款多",
            "支持退款",
            "支持分期"
          ],
          arr: [
            {
              img: STATIC_URL_PRE+"design/images/sale/mall/comp/wenhuajiarui_img1.jpg!width_250px",
              name: "中式茶桌南榆木茶台椅组合实…",
              a: "欧式风格",
              b: "金乌木",
              c: "",
              uv: 1000
            },
            {
              img: STATIC_URL_PRE+"design/images/sale/mall/comp/wenhuajiarui_img2.jpg!width_250px",
              name: "新中式茶桌椅组合 现代简约实…",
              a: "欧式风格",
              b: "金乌木",
              c: "",
              uv: 998
            },
            {
              img: STATIC_URL_PRE+"design/images/sale/mall/comp/wenhuajiarui_img3.jpg!width_250px",
              name: "新中式功夫泡茶桌原木高档茶桌",
              a: "欧式风格",
              b: "金乌木",
              c: "",
              uv: 789
            }
          ]
        },
      ]
    };
  },
  created() {
    this.comp.forEach((e)=>{
      e.logo = [e.logo]
    })
    this.arr = this.comp;
    console.log(this.arr);
  },
  mounted() {
    this.index = Array(this.arr.length).fill(0);
    this.translateX = Array(this.arr.length).fill(0);
  },
  methods: {
    goDesignZizhi(item) {
      this.$router.push("/zizhi/view/"+item.id);
    },
    toDetail(id) {
      this.$router.push(`/sale/mall/home/factory_detail/${id}`);
    },
    randomInt(n) {
      return Math.floor(Math.random() * n + 1);
    },
    prev(i) {
      if (this.index[i] === 0) {
        return;
      }
      this.index[i]--;
      const wrap = document.querySelector(".img-wrap-1");
      const W = wrap.offsetWidth;
      this.translateX[i] += W;
      this.$forceUpdate();
    },
    next(i) {
      if (this.index[i] === this.arr[i].logo.length - 1) {
        return;
      }
      this.index[i]++;
      const wrap = document.querySelector(".img-wrap-1");
      const W = wrap.offsetWidth;
      this.translateX[i] -= W;
      this.$forceUpdate();
    }
  }
};
</script>

<style scoped lang="less">
.container {
  width: 1200px;
  margin: 0 auto;
  .list {
    background-color: #fff;
    padding: 0 24px;
    .item-wrap {
      padding: 24px 0;
      border-top: 1px solid #ccc;
      &:first-child {
        border-top: 0;
      }
      .item {
        font-size: 12px;
        width: 264px;
        height: 356px;
        border: 1px solid #e6e6e6;
        box-shadow: 0 0 6px #dbe1f6;
        padding: 16px;
        margin-left: 16px;
        color: #808080;
        transform: scale(1);
        transition: all 0.3s;
        box-shadow: 0 3px 6px rgba(219, 225, 246, 1);
        &:first-child {
          margin-left: 0;
        }
        &:hover {
          border: 1px solid rgba(80, 116, 238, 1);
          transform: scale(1.064);
          box-shadow: 0 4px 8px rgba(219, 225, 246, 1);
        }
        .prev,
        .next {
          width: 36px;
          height: 36px;
          border-radius: 18px;
          position: absolute;
          top: 132px;
          margin-top: -18px;
          box-shadow: 0 0 6px #ccc;
          background-color: #fff;
          font-size: 22px;
          text-align: center;
          line-height: 36px;
          cursor: pointer;
          font-weight: bold;
          z-index: 2;
        }
        .prev {
          left: 5px;
        }
        .next {
          right: 5px;
        }
        .img-wrap {
          width: 232px;
          height: 232px;
          position: relative;
          overflow: hidden;
          .inner {
            height: 100%;
            transition: transform 0.3s;
            flex-wrap: nowrap;

            img {
              width: 100%;
              flex-shrink: 0;
            }
          }
        }
        .name {
          color: #292929;
          font-size: 18px;
          font-weight: bold;
          margin: 10px 0 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .label-wrap {
          height: 50px;
          overflow: hidden;
          span {
            // width: 65px;
            padding: 0 8px;
            height: 18px;
            border-radius: 9px;
            font-size: 8px;
            text-align: center;
            line-height: 18px;
            margin-right: 8px;
            margin-bottom: 8px;
            &:nth-child(3n) {
              margin-right: 0;
            }
            &.color-1 {
              background: rgba(246, 111, 106, 0.2);
              color: #fe8392;
            }
            &.color-2 {
              background: rgba(54, 155, 233, 0.2);
              color: #369be9;
            }
            &.color-3 {
              background: rgba(86, 129, 210, 0.2);
              color: #5681d2;
            }
            &.color-4 {
              background: rgba(75, 187, 165, 0.2);
              color: #4bbba5;
            }
            &.color-5 {
              background: rgba(144, 59, 204, 0.2);
              color: #903bcc;
            }
          }
        }
        .feature {
          margin-bottom: 10px;
          span {
            display: inline-block;
            border-right: 1px solid #808080;
            padding: 0 5px;
            line-height: 1;

            color: #808080;
            &:first-child {
              padding-left: 0;
            }
            &:last-child {
              border-right: 0;
            }
          }
        }
      }
    }
  }
}
</style>