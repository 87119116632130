import { render, staticRenderFns } from "./Comp2.vue?vue&type=template&id=54a3dd96&scoped=true&"
import script from "./Comp2.vue?vue&type=script&lang=js&"
export * from "./Comp2.vue?vue&type=script&lang=js&"
import style0 from "../style.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./Comp2.vue?vue&type=style&index=1&id=54a3dd96&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54a3dd96",
  null
  
)

export default component.exports