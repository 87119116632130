<template>
  <div class="container">
    <div class="list flex">
      <div v-for="(item, i) in arr" :key="i" class="item">
        <div @click="toDetail(item.id)" class="item-top">
          <img :src="IMG_URL_PRE+item.photoRender[0]+'!width_250px'" />
          <div class="name ellipsis2">{{item.name}}</div>
          <div class="feature">
            <span v-if="item.style">{{item.style.name}}</span>
            <span v-if="item.cat">{{item.cat.name}}</span>
            <span v-if="item.series">{{item.series.name}}</span>
          </div>
        </div>

        <div v-if="item.factoryComp" class="factory">{{item.factoryComp.name}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { STATIC_URL_PRE, IMG_URL_PRE } from '@/config';
export default {
  props: ['comp'],
  data() {
    return {
      IMG_URL_PRE,
      arr: [
        {
          name: "进口橡木欧式大床",
          img: require("../../../assets/images/sale/mall/rxk_img1.png"),
          a: "欧式风格",
          b: "实木靠椅",
          c: "康家合欧式金栗",
          factory: "江西家有儿女家具工厂"
        },
        {
          name: "简约现代舒适客厅沙发",
          img: require("../../../assets/images/sale/mall/rxk_img2.png"),
          a: "欧式风格",
          b: "",
          c: "轻奢系列",
          factory: "江西家有儿女家具工厂"
        },
        {
          name: "进口橡木欧式大床",
          img: require("../../../assets/images/sale/mall/rxk_img3.png"),
          a: "北欧风格",
          b: "原木色",
          c: "",
          factory: "江西家有儿女家具工厂"
        },
        {
          name: "进口橡木欧式大床",
          img: require("../../../assets/images/sale/mall/rxk_img4.png"),
          a: "北欧风格",
          b: "实木",
          c: "",
          factory: "江西家有儿女家具工厂"
        }
      ]
    };
  },
  created() {
    this.arr = this.comp;
    // console.log(this.arr);
  },
  methods:{
    toDetail(id) {
      this.$router.push(`/sale/mall/home/factory_detail/${id}`);
    },
  },
  
};
</script>
<style src="../style.css"></style>
<style scoped lang="less">
.container {
  width: 1200px;
  margin: 0 auto;
  .list {
    padding: 0 24px;
    .item {
      width: 272px;
      height: 428px;
      background-color: #fff;
      margin-right: 21px;
      margin-bottom: 24px;
      cursor: pointer;
      transition: all 0.3s;
      box-shadow: 0 3px 6px rgba(219, 225, 246, 1);
      transform: scale(1);
      &:nth-child(4n) {
        margin-right: 0;
      }
      &:hover {
        border: 1px solid rgba(80, 116, 238, 1);
        transform: scale(1.064);
        box-shadow: 0 4px 8px rgba(219, 225, 246, 1);
      }
      .item-top {
        padding: 20px 20px;
        img {
          display: block;
          width: 242px;
          height: 242px;
        }
        .name {
          color: #292929;
          font-size: 16px;
          margin: 14px 0;
        }
        .feature {
          font-size: 12px;
          color: #808080;
          span {
            display: inline-block;
            line-height: 1;
            border-left: 1px solid #808080;
            padding: 0 5px;
            &:first-child {
              padding-left: 0;
              border-left: 0;
            }
          }
        }
      }
      .factory {
        border-top: 1px solid #f5f5f5;
        padding: 15px 20px;
      }
    }
  }
}
</style>